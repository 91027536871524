<template>
  <div id="app">
    <TheHeader />
    <TheTopButton />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

    <TheFooter />

    <transition name="fade" mode="out-in">
      <TheLoading v-show="false" />
    </transition>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
import TheTopButton from '@/components/TheTopButton.vue'
import TheLoading from '@/components/TheLoading.vue'
//=====================
import Vue from "vue";
import { PaginationNavPlugin } from "bootstrap-vue";
Vue.use(PaginationNavPlugin);
//======================

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

//========================
import { PaginationPlugin } from 'bootstrap-vue'
Vue.use(PaginationPlugin)




export default {
  components: { TheHeader, TheFooter, TheTopButton, TheLoading },
  computed: {
    showLoading() {
      return this.$store.state.showLoading
    }
  }
}
</script>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
a{
  text-decoration: none !important;
}
.page-item.active .page-link {
  color:white !important;
  background-color: gray !important;
  border-color: gray !important;
}
.page-link{
  color:black!important;
}
.page-link:hover {
  color: white !important;
  text-decoration: none;
}

</style>
