import axios from 'axios'
import camelize from 'camelize2'

import store from '../store'

let requestArray = []

const axiosInstance = baseURL => {
  const instance = axios.create({
    baseURL: baseURL,
    headers: { 'Content-Type': 'application/json' }
  })

  // 攔截req

  instance.interceptors.request.use(
    config => {
      // Do something before request is sent
      // if (showLoading === 'true') {
      //   store.commit('setIsLoading', true)
      // }
      store.commit('setShowLoading', true)
      const url = config.url
      requestArray.push(url)

      return config
    },
    function(error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  // 攔截res
  instance.interceptors.response.use(
    function(response) {
      // store.commit('setIsLoading', false)
      const url = response.config.url
      requestArray = requestArray.filter(reqUrl => reqUrl !== url)
      if (requestArray.length === 0) {
        store.commit('setShowLoading', false)
      }

      return camelize(response)
    },
    function(error) {
      // store.commit('setIsLoading', false)
      if (error.response) {
        switch (error.response.status) {
          case 404:
            console.log('你要找的頁面不存在')
            // go to 404 page
            break
          case 500:
            console.log('程式發生問題')
            // go to 500 page
            break
          default:
            console.log(error.message)
        }
      }

      const url = error.response.config.url
      requestArray = requestArray.filter(reqUrl => reqUrl !== url)
      if (requestArray.length === 0) {
        store.commit('setShowLoading', false)
      }

      if (!window.navigator.onLine) {
        alert('網路出了點問題，請重新連線後重整網頁')
        return
      }
      return Promise.reject(error)
    }
  )
  return instance
}

export { axiosInstance }
