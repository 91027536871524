import { axiosInstance } from './axios'

const webAPI = axiosInstance(`${process.env.VUE_APP_WEB_API_BASE_URL}`)

export const apiGetBanner = () => {
  const formData = new FormData()
  formData.append('limit', 1)
  return webAPI.post('/banner', formData)
}

export const apiGetExhibit = () => {
  const formData = new FormData()
  return webAPI.post('/exhibit', formData)
}

export const apiGetELearning = type => {
  const formData = new FormData()
  formData.append('type', type)
  return webAPI.post('/elearning', formData)
}

export const apiGetMedia = type => {
  const formData = new FormData()
  formData.append('type', type)
  return webAPI.post('/media', formData)
}

export const apiSearch = keyword => {
  const formData = new FormData()
  formData.append('keyword', keyword)
  return webAPI.post('/search', formData)
}

export const apiGetMiningLandscape = () => {
  // https://www.gep.vm.ntpc.gov.tw/api/mining_landscape
  return webAPI.post('/mining_landscape')
}

export const apiGetOutDoor = () => {
  return webAPI.post('/outdoor_space')
}

export const apiGetCollections = params => {
  // id, keyword, title

  const formData = new FormData()
  if (params?.id) {
    formData.append('id', params.id)
  }

  if (params?.keyword) {
    formData.append('keyword', params.keyword)
  }

  if (params?.title) {
    formData.append('title', params.title)
  }
  return webAPI.post('/collections', formData)
}

export const apiGetRelics = params => {
  // id, keyword, title

  const formData = new FormData()
  if (params?.id) {
    formData.append('id', params.id)
  }

  if (params?.keyword) {
    formData.append('keyword', params.keyword)
  }

  if (params?.title) {
    formData.append('title', params.title)
  }
  return webAPI.post('/relics', formData)
}

export const apiGetStrollMap = params => {
  // type, sm_id

  const formData = new FormData()
  if (params?.type) {
    formData.append('type', params.type)
  }

  if (params?.id) {
    formData.append('sm_id', params.id)
  }

  return webAPI.post('/stroll_map', formData)
}

export const apiGetMiningStory = params => {
  // type, sm_id

  const formData = new FormData()
  if (params?.type) {
    formData.append('type', params.type)
  }

  if (params?.id) {
    formData.append('ms_id', params.id)
  }

  return webAPI.post('/mining_story', formData)
}

export const apiGetShueiJinJui = () => {
  return webAPI.post('/shueijinjui')
}
