<template>
  <div class="theLoading">
    <div class="theLoading__Img"></div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.theLoading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(#000000, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  &__Img {
    background-image: url('~@/assets/image/common/Loading.gif');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 47px;
    height: 47px;
  }
}
</style>
