import 'core-js/stable'
// optional but required for transforming generator fns.
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';


import '@/assets/css/reset.css'
import '@/assets/css/utils.css'

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由發生變化修改頁面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
