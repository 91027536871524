import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showLoading: false,
    exhibit: null,
    eLearning: null,
    eLearningYear: ''
  },
  mutations: {
    setShowLoading(state, showLoading) {
      state.showLoading = showLoading
    },
    setExhibit(state, exhibit) {
      state.exhibit = exhibit
    },
    setELearning(state, eLearning) {
      state.eLearning = eLearning
    },
    setELearningYear(state, eLearningYear) {
      state.eLearningYear = eLearningYear
    }
  },
  actions: {},
  modules: {}
})
