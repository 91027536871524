<template>
  <header class="header">
    <section class="container headerTop">
      <button
        @click="onClickMenu"
        class="headerTop__mobileMenuIcon clickable"
        title="開啓手機版選單"
      ></button>

      <div class="headerTop__logo">
        <a tabindex="1"
           role="button"
           v-on:keyup.enter="centerOnClick()"
           @click="centerOnClick()"
           class="header-link sr-only sr-only-focusable">跳到主要內容</a>
      </div>
      <router-link to="/" title="回首頁">
        <h1 class="headerTop__logo-center clickable">新北市立黃金博物館</h1>
      </router-link>

      <!-- 搜尋按鈕 -->

      <button
        @click="onClickSearchBtn"
        type="button"
        class="headerTop__mobileSearchButton clickable"
        title="點擊顯示搜尋"
        ref="searchBtn"
      >
        <img src="@/assets/image/common/Search.svg" alt="" />
      </button>

      <form
        @submit.prevent="onSubmitSearch"
        v-if="showInputContainer"
        class="headerTop__fixedInputContainer"
      >
        <label for="text" style="display: none;">文字說明</label>
        <div class="headerTop__inputContainer">
          <button
            @click="onSubmitSearch"
            type="submit"
            title="搜尋"
            class="headerTop__inputButton clickable"
          ></button>
          <input
            v-model="keyword"
            id="text"
            class="headerTop__input"
            type="text"
            ref="keywordInput"
            placeholder="請輸入文字說明"
            text="請輸入文字說明"
          />
        </div>

        <div class="headerTop__link-container">
          <a
            href="https://www.gep.ntpc.gov.tw/"
            title="新北市立黃金博物館官方網站(另開新視窗)"
            class="clickable headerTop__link"
            target="_blank"
            rel="noreferrer noopener"
            >官方網站</a
          >
          <hr class="clickable headerTop__divider" />
          <a
            href="https://www.facebook.com/goldmuseum/"
            title="新北市立黃金博物館Facebook粉絲專頁(另開新視窗)"
            class="clickable headerTop__link"
            target="_blank"
            rel="noreferrer noopener"
            >Facebook</a
          >
        </div>
      </form>
    </section>

    <nav v-if="showMenu" class="headerTop__nav">
      <h2 class="outline">網頁選單</h2>
      <ul class="container headerTop__ul">
        <li class="headerTop__closeLi">
          <button
            @click="onClickMenu"
            class="headerTop__closeMobileMenuButton clickable"
            title=""
          >
            <img src="@/assets/image/common/Close.svg" alt="" />
          </button>
        </li>

        <li v-for="(nav, idx) in navLists" :key="idx" class="headerTop__li">
          <h3 class="outline">{{ nav.title }}</h3>
          <router-link
            @click.native="e => onClickFirstLink(e, nav)"
            :to="isDesktop ? nav.url : '#'"
            :title="`連結至${nav.title}`"
            class="headerTop__navLink clickable"
            >{{ nav.title }}
            <span v-if="nav.subNavLists" class="headerTop__arrow"></span>
          </router-link>

          <ul class="headerTop__secondUl">
            <li
              v-for="(subNav, idx) in nav.subNavLists"
              :key="idx"
              class="headerTop__secondLi"
            >
              <h4 class="outline">{{ subNav.title }}</h4>
              <router-link
                @click.native="onClickSecondLink"
                :to="subNav.url"
                :title="`連結至${subNav.title}`"
                class="headerTop__secondLink"
                >{{ subNav.title }}</router-link
              >
            </li>
          </ul>
        </li>
      </ul>
      <div class="headerTop__link-container--mobile">
        <a
          href="https://www.gep.ntpc.gov.tw/"
          class="clickable headerTop__link"
          target="_blank"
          rel="noreferrer noopener"
          >官方網站</a
        >
        <hr class="clickable headerTop__divider" />
        <a
          href="https://www.facebook.com/goldmuseum/"
          class="clickable headerTop__link"
          target="_blank"
          rel="noreferrer noopener"
          >Facebook
        </a>
      </div>
    </nav>
  </header>
</template>

<script>
import AnchorRouterLink from 'vue-anchor-router-link'
import $ from 'jquery'
export default {
  name: 'PageHeader',
  components: {
    AnchorRouterLink
  },
  data() {
    return {
      disableBtn: false,
      keyword: '',
      navLists: [
        {
          title: '展示',
          url: '/exhibit',
          subNavLists: [
            { title: '常設展', url: '/exhibit/normal' },
            { title: '特展', url: '/exhibit/special' }
          ]
        },
        {
          title: '戶外空間',
          url: '/outdoor'
        },
        {
          title: '典藏',
          url: '/publication',
          subNavLists: [
            { title: '典藏查詢', url: '/publication/query' },
            { title: '3D文物檢索', url: '/publication/3d' }
          ]
        },
        {
          title: '礦業景觀',
          url: '/mining-landscape'
        },
        {
          title: '文化行旅',
          url: '/culture',
          subNavLists: [
            { title: '散步地圖', url: '/culture/map' },
            { title: '水金九景點', url: '/culture/water-gold' },
            { title: '礦山故事', url: '/culture/story' }
          ]
        },
        {
          title: '數位學習',
          url: '/e-learning',
          subNavLists: [
            { title: '國小', url: '/e-learning/elementary' },
            { title: '國中', url: '/e-learning/junior' },
            { title: '礦山講堂', url: '/e-learning/mine' }
          ]
        },
        {
          title: '影音專區',
          url: '/video',
          redirect: '/video/activity',
          subNavLists: [
            { title: '導覽影片', url: '/video/navigation' },
            { title: '活動記事', url: '/video/activity' },
            { title: '礦山故事', url: '/video/mine' }
          ]
        }
      ],
      showInputContainer: true,
      showMenu: true,
      isDesktop: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.setShowAllContainer)
    this.setShowAllContainer()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setShowAllContainer)
  },
  methods: {
    //scroll 中間區塊
    centerOnClick: function() {
      if (this.$route.name === 'ExhibitNormal') {
        window.scroll(0, 250)
      } else if (this.$route.name === 'ExhibitSpecial') {
        window.scroll(0, 250)
      }else if (this.$route.name === 'Outdoor') {
        window.scroll(0, 100)
      } else if (this.$route.name === 'PublicationQuery' || this.$route.name === 'Publication3D') {
        window.scroll(0, 500)
      } else if (this.$route.name === 'CultureMap' || this.$route.name === 'CultureStory') {
        window.scroll(0, 600)
      } else if (this.$route.name === 'ELearningElementary' || this.$route.name === 'ELearningJunior' || this.$route.name ==='ELearningMine') {
        window.scroll(0, 500)
      } else if (this.$route.name === 'VideoNavigation' || this.$route.name === 'VideoActivity' || this.$route.name === 'VideoMine') {
        window.scroll(0, 500)
      } else {
        window.scroll(0, 300)
        $('.fc-button-primary').focus()
      }
    },
    scrollFix: function(hashbang) {
      location.hash = hashbang
    },
    toggleMenuStatus() {
      this.menuStatus = !this.menuStatus
    },
    closeMenuStatus() {
      this.menuStatus = false
      //處理focus判斷
      let id = this.$route.hash
      // console.log('id', id)
      if (this.$route.hash) {
        let pos = $(`${id}`).offset().top
        $('body, html').animate({ scrollTop: pos })
        if (id === '#secNews') {
          $('.pager-pages a').attr('tabindex', 0)
          $('.newsList a').focus()
        }
        if (id === '#secWeather') {
          $('.fc-button-primary').focus()
        }
        if (id === '#secParkInfo') {
          $('#tabBtn_parkinfo').focus()
        }
        if (id === '#secAround') {
          $('.cfix .active button').focus()
        }
        // if (id === '#secFaq') {
        //   $('.active button').focus()
        // }
        if (id === '#secContact') {
          $('#name').focus()
        }
      }
    },
    onClickSearchBtn(e) {
      this.showInputContainer = !this.showInputContainer
    },
    setShowAllContainer() {
      const windowWidth = window.innerWidth
      if (windowWidth > 768) {
        this.isDesktop = true
        this.showInputContainer = true
        this.showMenu = true
        document.body.style.overflow = 'auto'
        return
      }

      if (!this.isDesktop) {
        return
      }

      this.isDesktop = false
      this.showInputContainer = false
      this.showMenu = false
    },
    onClickMenu() {
      this.showMenu = !this.showMenu
      if (this.showMenu) {
        document.body.style.overflow = 'hidden'
        return
      }
      document.body.style.overflow = 'auto'
    },
    onClickFirstLink(e, nav) {
      if (this.isDesktop) return

      if (!nav.subNavLists) {
        this.$router.push(nav.url)
        this.onClickMenu()
        return
      }

      const allSecondUlElement = document.querySelectorAll(
        '.headerTop__secondUl'
      )

      const allArrowElement = document.querySelectorAll('.headerTop__arrow')

      allArrowElement.forEach(el => {
        el.style.transform = 'none'
      })

      const currentSiblingElement = e.target.nextSibling
      for (const el of allSecondUlElement) {
        if (
          el === currentSiblingElement &&
          !!currentSiblingElement.style.display
        ) {
          continue
        }
        el.style.display = 'none'
      }

      if (currentSiblingElement.style.display === 'none') {
        currentSiblingElement.style.display = 'block'
        const currentArrowElement = e.target.querySelector('.headerTop__arrow')
        currentArrowElement.style.transform = 'rotate(180deg)'
      } else {
        currentSiblingElement.style.display = 'none'
      }
      currentSiblingElement.classList.toggle('hidden')
      currentSiblingElement.classList.toggle('block')
    },
    onClickSecondLink() {
      if (this.isDesktop) return
      this.showMenu = false
      document.body.style.overflow = 'auto'
    },
    onSubmitSearch(e) {
      const routeKeyword = this.$route.query.keyword
      if (!this.keyword || routeKeyword === this.keyword) return
      const windowWidth = window.innerWidth
      if (windowWidth < 768) {
        this.showInputContainer = false
      }

      this.$router.push({
        name: 'Search',
        query: {
          keyword: this.keyword
        }
      })
    }
  }
}
</script>

<style lang="scss">
.header {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
}

.headerTop {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 6.3rem;

  &__logo,
  &__logo-center {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__logo {
    width: 14.7rem;
    height: 4.5rem;
  }

  &__logo > a {
    color:white;
    opacity:0;
  }

  &__logo > a:focus-within {
    font-size:1.75rem;
    color: #595757;
    opacity:1;
  }

  &__logo-center {
    background-image: url('~@/assets/image/common/LogoTw.png');
    width: 40.9rem;
    height: 7.4rem;
    text-indent: 101%;
    white-space: nowrap;
    overflow: hidden;
  }

  &__inputButton {
    width: 3rem;
    height: 3rem;
    background-image: url('~@/assets/image/common/Search.png');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    border: none;
    border-radius: 17.5px;
    z-index: 1;
    left: 0.5rem;
    top: 0.3rem;
  }
  &__input {
    background-color: #ececec;
    border-radius: 17.5px;
    border: none;
    padding: 0.8rem;
    padding-left: 4.7rem;
    width: 20rem;
    font-size: 1.2rem;
    line-height: 2rem;
    color: #595757;
    margin-bottom: 1.9rem;
  }

  &__inputContainer {
    position: relative;
    border-radius: 17.5px;
    width: 20rem;
  }

  &__link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 14.7rem;
    margin: 0 auto;
  }

  &__link-container--mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 14.7rem;
    margin: 0 auto;
    margin-top: 3rem;
    display: none;
  }

  &__link {
    color: #595757;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2rem;
  }

  &__divider {
    height: 14px;
    border-right: 1px solid #727171;
    width: 1px;
  }

  &__nav {
    margin-top: 5.4rem;
  }

  &__ul {
    display: flex;
  }

  &__li {
    flex: 1;
    padding: 0 2px;
    position: relative;
  }

  &__li:focus-within > &__secondUl,
  &__li:hover > &__secondUl {
    opacity: 1;
    visibility: visible;
  }

  &__secondUl {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    border-bottom: 2px solid #ffffff;
    box-shadow: 25px 25px 50px 0 rgb(0 0 0 / 25%);
    transition: all 0.3s ease-in-out;
    z-index: 2;
  }

  &__navLink,
  &__secondLink {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.6rem;
    transition: all 0.3s ease-in-out;
    letter-spacing: 0.4rem;
    padding-bottom: 0.7rem;
  }

  &__navLink {
    color: #595757;
    border-bottom: 5px solid #dadbdb;
  }

  &__secondLink {
    background-color: #d0a805;
    opacity: 0.9;
    border: 2px solid #ffffff;
    border-bottom: none;
    padding: 1.7rem 0;
    color: white;
  }

  &__navLink--active,
  &__navLink:hover {
    border-bottom: 5px solid #d0a805;
    color: #d0a805;
  }

  &__mobileMenuIcon {
    margin-left: 2rem;
    width: 20px;
    height: 13px;
    border: none;
    border-top: 1px solid #d0a805;
    border-bottom: 1px solid #d0a805;
    background-color: #d0a805;
    padding: 5px 0;
    background-clip: content-box;
    display: none;
  }

  &__mobileSearchButton {
    border: none;
    background-color: #d0a805;
    width: 6.6rem;
    height: 6.6rem;
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }

  &__closeLi {
    display: none;
  }

  &__fixedInputContainer {
    position: relative;
  }
}

@media (max-width: 768px) {
  .headerTop {
    justify-content: flex-start;
    align-items: center;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;

    &__mobileMenuIcon {
      margin-right: 1.6rem;
      display: block;
    }
    &__logo {
      display: none;
    }

    &__logo-center {
      width: 13.4rem;
      height: 3rem;
    }

    &__fixedInputContainer {
      position: fixed;
      top: 65px;
      z-index: 2;
      background-color: #d0a805;
      width: 100vw;
      right: 0;
      left: 0;
    }

    &__inputButton {
      background-color: transparent;
      background-image: url('~@/assets/image/common/Search.svg');
      top: 1.55rem;
    }

    &__inputContainer {
      border-radius: 0;
      width: 100%;
      height: 100%;
    }

    &__input {
      width: 100%;
      height: 100%;
      margin: 0;
      background-color: #595757;
      opacity: 0.9;
      color: white;
      padding-top: 2.2rem;
      padding-bottom: 2.2rem;

      border-radius: 0;
    }

    &__input::placeholder {
      color: white;
    }

    &__link-container {
      display: none;
    }

    &__mobileSearchButton {
      display: block;
    }

    &__nav {
      margin: 0;
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      z-index: 2;
      padding: 2.8rem 0;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    &__ul {
      flex-direction: column;
    }

    &__closeLi {
      display: block;
    }

    &__closeMobileMenuButton {
      border: none;
      background-color: white;
    }

    &__navLink {
      padding: 1.6rem 0;
      border-bottom: 1px solid #dadbdb;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__navLink:hover {
      border-bottom: 1px solid #d0a805;
    }

    &__secondUl {
      position: relative;
      box-shadow: none;
      border: none;
      background-color: #595757;
      width: 100vw;
      margin-left: -3.3%;
      padding-left: 3%;
      visibility: visible;
      opacity: 1;
      display: none;
    }

    &__secondLink {
      border: none;
      text-align: left;
      background-color: transparent;
    }

    &__arrow {
      width: 1.4rem;
      height: 0.7rem;
      background-image: url('~@/assets/image/common/ArrowDown.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &__link-container--mobile {
      display: flex;
    }
  }
}
</style>
