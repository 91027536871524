import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index/Index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,


  },
  {
    path: '/exhibit',
    name: 'Exhibit',
    redirect: '/exhibit/normal',
    component: () => import('@/views/Exhibit/Exhibit.vue'),
    meta: {
      title: '新北市立黃金博物館 展示',
    },
    children: [
      {
        path: 'normal',
        name: 'ExhibitNormal',
        component: () => import('@/views/Exhibit/Normal.vue'),
        meta: {
          title: '新北市立黃金博物館 常設展'
        },
      },
      {
        path: 'special',
        name: 'ExhibitSpecial',
        component: () => import('@/views/Exhibit/Special.vue'),
        meta: {
          title: '新北市立黃金博物館 特展'
        },
      }
    ]
  },
  {
    path: '/e-learning',
    name: 'ELearning',
    redirect: '/e-learning/elementary',
    component: () => import('@/views/ELearning/ELearning.vue'),
    children: [
      {
        path: 'elementary',
        name: 'ELearningElementary',
        component: () => import('@/views/ELearning/Elementary.vue'),
        meta: {
          title: '新北市立黃金博物館 國小'
        }
      },
      {
        path: 'junior',
        name: 'ELearningJunior',
        component: () => import('@/views/ELearning/Junior.vue'),
        meta: {
          title: '新北市立黃金博物館 國中'
        }
      },
      {
        path: 'Mine',
        name: 'ELearningMine',
        component: () => import('@/views/ELearning/Mine.vue'),
        meta: {
          title: '新北市立黃金博物館 礦山講堂'
        }
      },
      {
        path: 'detail',
        name: 'ELearningDetail',
        component: () => import('@/views/ELearning/Detail.vue'),
        meta: {
          title: ''
        }
      }
    ]
  },
  {
    path: '/video',
    name: 'Video',
    redirect: '/video/activity',
    component: () => import('@/views/Video/Video.vue'),
    children: [
      {
        path: 'activity',
        name: 'VideoActivity',
        component: () => import('@/views/Video/Activity.vue'),
        meta: {
          title: '新北市立黃金博物館 活動記事'
        }
      },
      {
        path: 'navigation',
        name: 'VideoNavigation',
        component: () => import('@/views/Video/Navigation.vue'),
        meta: {
          title: '新北市立黃金博物館 導覽影片'
        }
      },
      {
        path: 'mine',
        name: 'VideoMine',
        component: () => import('@/views/Video/Mime.vue'),
        meta: {
          title: '新北市立黃金博物館 礦山故事'
        }
      }
    ]
  },
  {
    path: '/coming/:name',
    name: 'Coming',
    component: () => import('@/views/Coming/Coming.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/Search/Search.vue')
  },
  {
    path: '/mining-landscape',
    name: 'MiningLandscape',
    component: () => import('@/views/MiningLandscape/MiningLandscape.vue'),
    meta: {
      title: '新北市立黃金博物館 礦物景觀'
    },
  },
  {
    path: '/culture',
    name: 'Culture',
    redirect: '/culture/map',
    component: () => import('@/views/Culture/Culture.vue'),
    children: [
      {
        path: 'map',
        name: 'CultureMap',
        component: () => import('@/views/Culture/Map.vue'),
        meta: {
          title: '新北市立黃金博物館 散步地圖'
        },
      },
      {
        path: 'story',
        name: 'CultureStory',
        component: () => import('@/views/Culture/Story.vue'),
        meta: {
          title: '新北市立黃金博物館 礦山故事'
        },
      },
      {
        path: 'water-gold',
        name: 'CultureWaterGold',
        component: () => import('@/views/Culture/WaterGold.vue'),
        meta: {
          title: '新北市立黃金博物館 水金九景點'
        },
      },
    ],
  },
  {
    // 水湳洞 ShueiNanDong
    // 金瓜石 JinGuaShih
    // 九份 JiouFen
    path: '/shuei-nan-dong',
    name: 'ShueiJinJiouShueiNanDong',
    component: () => import('@/views/ShueiJinJiou/ShueiJinJiou.vue')
  },
  {
    // 水湳洞 ShueiNanDong
    // 金瓜石 JinGuaShih
    // 九份 JiouFen
    path: '/jin-gua-shih',
    name: 'ShueiJinJiouJinGuaShih',
    component: () => import('@/views/ShueiJinJiou/ShueiJinJiou.vue')
  },
  {
    // 水湳洞 ShueiNanDong
    // 金瓜石 JinGuaShih
    // 九份 JiouFen
    path: '/jiou-fen',
    name: 'ShueiJinJiouJiouFen',
    component: () => import('@/views/ShueiJinJiou/ShueiJinJiou.vue')
  },
  {
    path: '/shuei-jin-jiou-detail',
    name: 'ShueiJinJiouDetail',
    component: () => import('@/views/ShueiJinJiouDetail/ShueiJinJiouDetail.vue')
  },
  {
    path: '/map-detail',
    name: 'CultureMapDetail',
    component: () => import('@/views/CultureMapDetail/CultureMapDetail.vue'),
  },
  {
    path: '/story-detail',
    name: 'CultureStoryDetail',
    component: () => import('@/views/CultureStoryDetail/CultureStoryDetail.vue'),
  },
  {
    path: '/outdoor',
    name: 'Outdoor',
    component: () => import('@/views/OutDoor/OutDoor.vue'),
    meta: {
      title: '新北市立黃金博物館 戶外空間'
    },
  },
  {
    path: '/publication',
    name: 'Publication',
    redirect: '/publication/query',
    component: () => import('@/views/Publication/Publication.vue'),
    children: [
      {
        path: 'query',
        name: 'PublicationQuery',
        component: () => import('@/views/Publication/Query.vue'),
        meta: {
          title: '新北市立黃金博物館 典藏查詢'
        },
      },
      {
        path: '3d',
        name: 'Publication3D',
        component: () => import('@/views/Publication/3D.vue'),
        meta: {
          title: '新北市立黃金博物館 3D文物檢索'
        },
      },
    ]
  },
  {
    path: '/publication-detail',
    name: 'PublicationDetail',
    component: () => import('@/views/PublicationDetail/PublicationDetail.vue'),
  },
  {
    path: '/3d-detail',
    name: 'Publication3DDetail',
    component: () => import('@/views/ThreeDimensionsDetail/ThreeDimensionsDetail.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return desired position
    if (to.hash && to.hash !== '#') {
      return {
        selector: to.hash
      }
    }
  }
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

export default router
