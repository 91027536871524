<template>
  <main class="index">
    <h2 class="outline">首頁</h2>
    <section>
      <h2 class="outline">首頁Banner</h2>
      <div
        @click="onClickImg"
        class="index__carouselImg index__carouselImg--desktop"
        :class="bannerObj.bUrlZh ? 'clickable' : ''"
        :style="`background-image: url(${baseURL}${bannerObj.bImageZh})`"
      ></div>

      <div
        @click="onClickImg"
        class="index__carouselImg index__carouselImg--mobile"
        :class="bannerObj.bUrlZh ? 'clickable' : ''"
        :style="`background-image: url(${baseURL}${bannerObj.bImageMobileZh})`"
      ></div>
    </section>
  </main>
</template>

<script>
// import { Carousel, Slide } from 'vue-carousel'
import { apiGetBanner } from '@/api/webAPI'

export default {
  components: {},
  data() {
    return {
      bannerObj: {
        bCreateTimestamp: '',
        bEnabled: 'Y',
        bId: '',
        bImageEn: null,
        bImageMobileEn: null,
        bImageMobileZh: '',
        bImageZh: '',
        bOrder: '0',
        bTitleEn: null,
        bTitleZh: '輪播11',
        bType: 'banner',
        bUrlEn: null,
        bUrlZh: 'https://www.gep.ntpc.gov.tw/'
      }
    }
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_WEB_BASE
    }
  },
  methods: {
    onClickImg() {
      if (!this.bannerObj.bUrlZh) return
      window.open(this.bannerObj.bUrlZh, '_blank')
    }
  },
  async created() {
    const bannerResponse = await apiGetBanner()
    const bannerImageObj = bannerResponse.data.data[0]
    this.bannerObj = bannerImageObj
  }
}
</script>

<style lang="scss">
.index {
  &__carouselImg {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__carouselImg--desktop {
    width: 100%;
    padding-bottom: 56.25%;
    // height: calc(100vw * 0.5625);
    // background-color: black;
  }

  &__carouselImg--mobile {
    display: none;
  }

  &__link--desktop {
    display: inline;
  }

  &__link--mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .index {
    &__carouselImg--desktop {
      display: none;
    }

    &__carouselImg--mobile {
      padding-bottom: 141.866666667%;
      display: block;
    }

    &__link--desktop {
      display: none;
    }

    &__link--mobile {
      display: inline;
    }
  }
}
</style>
