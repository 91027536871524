<template>
  <footer class="footer">
    <h2 class="outline">Footer</h2>
    <section class="container footer__container">
      <h2 class="outline">
        Copyright © 新北市立黃金數位博物館. All rights reserved.
      </h2>
      <div class="footer__link--fake"></div>
      <p>Copyright © 新北市立黃金數位博物館. All rights reserved.</p>
      <a href="https://accessibility.moda.gov.tw/Applications/Detail?category=20211116162456" target="_blank" title="無障礙網站">
        <img
          width="112px" height="40px"
          src="@/assets/image/common/accessibility_a.png"
          alt="通過A無障礙網頁檢測"
        />
      </a>
    </section>

    <!-- A footer for the page. -->
  </footer>
</template>

<script>
import $ from 'jquery'
export default {
  data(){
    return{}
  },
  computed:{},
  created(){},
  mounted(){
      $('ul.pagination >:first-child').attr('title', '上一頁')
      $('ul.pagination >:last-child').attr('title', '下一頁')
      window.addEventListener('load',function(){
      // let btnContainer = document.getElementsByClassName("BasePagination")[0];
      // btnContainer.innerHTML = btnContainer.innerHTML.replaceAll("<button", "<a href=\"#\"><button").replaceAll("</button>", "</button></a>");
      // btnContainer.innerHTML = btnContainer.innerHTML.replaceAll("<li", "<a href=\"#\"><li").replaceAll("</li>", "</li></a>");
      // console.log("btnContainer => ");
      // console.log(btnContainer.children[0]);
      })
  }
}
</script>

<style lang="scss">
.footer {
  background: #727171;
  padding: 1.8rem 0;
  font-size: 1.3rem;
  line-height: 2.2rem;
  text-align: center;
  color: #ffffff;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__link,
  &__link--fake {
    width: 11.2rem;
    height: 4rem;
  }

  &__link img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .footer {
    &__link--fake {
      display: none;
    }

    &__container {
      flex-direction: column;
    }

    &__link {
      margin-top: 0.4rem;
    }
  }
}
</style>
